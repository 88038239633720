import React, { Component } from 'react'
import HeaderMenu from 'components/Header/HeaderMenu/HeaderMenu'
import MobileMenu from 'components/Header/MobileMenu/MobileMenu'
import { Link } from "gatsby"
import { getNewUrl } from 'utils/routing'
import './header.scss'
import SearchBox from 'components/SearchBox/SearchBox'
import SmoothCollapse from 'react-smooth-collapse'
import { getImage } from 'utils/get-image'

import { 
    getSlug,
    getLabel,
    getLanguageFromPath,
    getColour,
    isMobile,
    getCurrentSection
} from 'utils'

export class Header extends Component {
    state = {
        language: 0,
        shouldHideMobileMenu: false
    }

    headerContainer = null
    headerHeight = null
    previousScrollHeight = 0

    swapLanguage = languageSlug => {
        const newUrl = getNewUrl(this.props.path, languageSlug)

        return newUrl
    }
    
    componentWillMount() {
        if (typeof window != 'undefined') {
            window.addEventListener('scroll', this.handleScroll)   
        }
    }

    componentWillUnmount() {
        if (typeof window != 'undefined') {
            window.removeEventListener('scroll', this.handleScroll)
        }
    }

    handleScroll = e => {
        const {
            shouldHideMobileMenu
        } = this.state

        if(isMobile()){
            const scrollTop = window.pageYOffset

            let _shouldHideMobileMenu = false

            if(scrollTop > 50 && scrollTop > this.previousScrollHeight){
                _shouldHideMobileMenu = true
            }

            if(_shouldHideMobileMenu != shouldHideMobileMenu){
                this.setState({
                    shouldHideMobileMenu: _shouldHideMobileMenu
                })
            }

            this.previousScrollHeight = scrollTop
        }

    }

    componentDidMount() {
        if (typeof window != 'undefined') {
            window.requestAnimationFrame(_ => {
                if (this.headerContainer) {
                    this.headerHeight = this.headerContainer.getBoundingClientRect().height
                    this.setHeaderHeight()
                }
            })
        }
    }

    setHeaderHeight = _ => {
        if (typeof window != 'undefined') {
            window.requestAnimationFrame(_ => {
                if (this.headerContainer) {
                    const headerHeight = this.headerContainer.getBoundingClientRect().height
                    document.body.style.marginTop = headerHeight + 'px'
                }
            })
        }
    }

    render() {
        const {
            allLanguages,
            location,
            currentLanguage,
            languageIndex,
            data
        } = this.props

        const {
            shouldHideMobileMenu
        } = this.state

        const headerStyle = {
            marginBottom: this.headerHeight + 'px'
        }

        const currentSection = getCurrentSection(location)

        const {
            bannerMessage,
            searchPlaceholder,
            searchButtonLabel,
            mobileMenuMenuLabel,
            mobileMenuCloseLabel,
            mobileMenuBackLabel,
            clinicBoxCopy
        } = allLanguages[languageIndex].node

        const bannerUrl = `/${currentLanguage}/community-health/covid-19`

        return (
            <div
                className={"header " + currentSection}
                ref={ref => this.headerContainer = ref}
                style={headerStyle}
            >
                <SmoothCollapse expanded={!shouldHideMobileMenu}>
                    <div className="top-strip">
                        <ul className="language-switch">
                            {allLanguages.map(language => {
                                return (
                                    <li
                                        key={language.node.node_locale}
                                        className={currentLanguage == language.node.node_locale ? 'active' : null}
                                    >
                                        <Link to={this.swapLanguage(language.node.node_locale)} replace>
                                            {language.node.name}
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                        <SearchBox
                            isTopBar={true}
                            searchPlaceholder={searchPlaceholder}
                            searchButtonLabel={searchButtonLabel}
                        />
                    </div>
                </SmoothCollapse>
                <div className="banner">
                    <div className="content">
                        <img src={getImage('alert-white.png')}/>
                        <p>
                            {bannerMessage}
                        </p>
                        <a href={bannerUrl}>
                            here
                        </a>
                        <p>
                            .
                        </p>
                    </div>
                </div>
                <HeaderMenu
                    location={location}
                    menuItems={data}
                />
                <MobileMenu
                    location={location}
                    menuItems={data}
                    menuLabel={mobileMenuMenuLabel}
                    closeLabel={mobileMenuCloseLabel}
                    backLabel={mobileMenuBackLabel}
                    clinicBoxCopy={clinicBoxCopy}
                    shouldHideMobileMenu={shouldHideMobileMenu}
                />
            </div>
        )
    }

}

export default Header;
