import React, { Component } from 'react'
import './footer.scss'
import logo from 'content/shifra-logo.png'
import facebookIcon from 'content/footer/facebook-icon.png'
import instaIcon from 'content/footer/insta-icon.png'
import moment from 'moment'
import { isMobile } from 'utils/screen'
import { getImage } from 'utils/get-image'
import { getLanguageFromPath } from 'utils/routing'
import Link from 'gatsby-link'

class Footer extends Component {
    state = {
        language: 0
    }

    componentWillMount() {
        this.setState({
            language: this.props.language,
            defaultLanguage: this.props.language,
            search: this.props.search,
        });
    }

    componentWillReceiveProps(props) {
        this.setState(
            Object.assign(this.state, {
                language: props.language,
            })
        );
    }

    // given a logo, load the relevant icon
    getIconForLogo = logo => {
        let logoIcon = '#';

        // try to get the image
        try {
            logoIcon = require(`content/contributors/${logo}.png`);
        } catch (e) {
            logoIcon = null;
        }

        return logoIcon;
    }

    getLogoRows = _ => {
        const logos = [
            {
                slug: 'swinburne',
                name: 'Swinburne',
                url: 'https://www.swinburne.edu.au/'
            }, {
                slug: 'ygap',
                name: 'YGAP',
                url: 'https://ygap.org/'
            }, {
                slug: 'monash',
                name: 'Monash',
                url: 'https://www.monash.edu/'
            }, {
                slug: 'gcgmonash',
                name: 'Global Consulting Group',
                url: 'https://www.gcg.org.au'
            }, {
                slug: 'sylaba',
                name: 'Sylaba',
                url: 'https://sylaba.com.au/'
            }, {
                slug: 'minter',
                name: 'Minter Ellison',
                url: 'https://www.minterellison.com/'
            }, {
                slug: 'yc',
                name: 'YC',
                url: ' https://yourcreative.com.au'
            }, {
                slug: 'techfugees',
                name: 'Techfugees',
                url: ' https://techfugees.com'
            }
        ]

        const logosPerRow = isMobile() ? 999 : Math.ceil(logos.length / 2)

        let logoRows = [[]]

        if (!isMobile()) {
            logoRows.push([])
        }

        logos.forEach((logo, index) => {
            if (index < logosPerRow) {
                logoRows[0].push(logo)
            } else {
                logoRows[1].push(logo)
            }
        })

        return logoRows
    }

    render() {
        const {
            data,
            location
        } = this.props

        // the logo stuff is a bit over-engineered, but it's always hard to make a bunch of logos look neat.
        const logoRows = this.getLogoRows()

        const currentLanguage = getLanguageFromPath(location.pathname)

        const phoneNumberAHref = data ? 'tel:' + data.phoneNumberA : null
        const phoneNumberBHref = data ? 'tel:' + data.phoneNumberB : null

        const flags = ['aboriginal','atsi', 'pride']

        return (
            <footer className="footer">
                <div className="content has-text-centered top">
                    <div>
                        <a className="footerLink" href={phoneNumberAHref}>
                            <img src={getImage('footer/phone.png')} />
                            <span>
                                {data ? data.phoneNumberALabel : null}
                            </span>
                            <span>
                                {data ? data.phoneNumberA : null}
                            </span>
                        </a>
                        <a className="footerLink" href={phoneNumberBHref}>
                            <img src={getImage('footer/phone.png')} />
                            <span>
                                {data ? data.phoneNumberBLabel : null}
                            </span>
                            <span>
                                {data ? data.phoneNumberB : null}
                            </span>
                        </a>
                    </div>
                </div>
                <div className="content has-text-centered middle">
                    <ul className="halves clearfix">
                        <li>
                            <ul className="clearfix columns-container">
                                <li>
                                    <img src={logo} className="shifra-logo" />
                                    <div className="social-links">
                                        <a
                                            className="footerLink socialIconLink"
                                            href={'https://www.facebook.com/Shifra-1777571399195093/'}
                                        >
                                            <img src={facebookIcon} />
                                        </a>
                                        <a
                                            className="footerLink socialIconLink"
                                            href={'https://www.instagram.com/Shifra_au/'}
                                        >
                                            <img src={instaIcon} />
                                        </a>
                                    </div>
                                </li>
                                <li>
                                    <ul className="clearfix links">
                                        {data.links.map((link, index) => {
                                            return (
                                                <li className="footerLink" key={index}>
                                                    {index == 1 ?
                                                        <a href="mailto:info@shifra.io">
                                                            {link.label}
                                                        </a>
                                                        :
                                                        <Link to={`/${currentLanguage}${link.url}`}>
                                                            {link.label}
                                                        </Link>
                                                    }
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="logo-half">
                            <ul className="logos clearfix">
                                {data.logos.map((logo, logoIndex) => (
                                        <li key={logoIndex}>
                                            <a href={logo.url} target="_blank">
                                                <img src={logo.file.url} alt={`${logo.name} Logo`} />
                                            </a>
                                        </li>
                                    )
                                )}
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="disclaimer">
                    <div className="left">
                        <p>
                            © {moment().format('Y') + ' ' + data.disclaimerLine1}
                        </p>
                        <p>
                            {data.disclaimerLine2.disclaimerLine2}
                        </p>
                    </div>
                    <div className="right">
                        <ul>
                            { flags.map(flag => {
                                return (
                                    <li key={flag}>
                                        <img src={getImage('flags/'+flag+'.png')} />
                                    </li>
                                )
                            })}
                        </ul>
                        <p>
                            Shifra acknowledges the Australian Aboriginal and Torres Strait Islander peoples as the first inhabitants of the nation and the traditional custodians of the lands where we live, learn and work.
                        </p>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
