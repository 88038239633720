import React, { Component } from 'react'
import Link from 'gatsby-link'

import {
    getLabel,
    getSlug
} from 'utils'

export class HeaderSubMenuItem extends Component {
    state = {
        isOpen: false
    }

    toggleOpenItem = _ => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        const {
            item,
            parent
        } = this.props

        const slug = getSlug(item)

        // kinda messy - sometimes parent is /ar/ and sometimes /ar/thing
        const thisPath = `${parent}${parent.endsWith('/') ? '' : '/' }${slug}`

        return (
            <li key={slug}>
                <Link to={thisPath}>
                    <label>
                        { getLabel(item) }
                    </label>
                </Link>
            </li>
        )
    }

}

export default HeaderSubMenuItem;
