import React, { Component } from 'react'
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import { StaticQuery, graphql } from 'gatsby'
import { getLanguageFromPath } from 'utils/routing'
import { getLanguageIndex } from 'utils/language'
import { getImage } from 'utils/get-image'
import './index.scss'
import { Location } from '@reach/router'
import Cookies from 'js-cookie'
import {navigate} from 'gatsby'

class LayoutWrapper extends Component {
    pageSlug = ''
    yVal = 0

    state = {
        modalVisible: false
    }

    componentWillUnmount() {
        window.scrollData[this.pageSlug] = window.pageYOffset
    }

    componentDidMount() {
        window.requestAnimationFrame(() => {
            if(window.scrollData) {
                this.yVal = window.scrollData[this.pageSlug]
    
                window.scrollTo(0, this.yVal)
            } else {
                window.scrollData = {[this.pageSlug]: 0}
            }
        })

        const cookieSeen = Cookies.get('modalSeen')

        console.log('cooke equals'+cookieSeen)

        if(cookieSeen == undefined){
            console.log('isnt true')
            setTimeout(
                function() {
                    this.setState({modalVisible: true});
                    
                }
                .bind(this),
                1000
            );
        }
    }

    closeModal = () => {

        this.setState({
            modalVisible: false
        })
        
        Cookies.set('modalSeen', true)
    }

    yesPlease = (currentLanguage) => {

        this.setState({
            modalVisible: false
        })
        
        Cookies.set('modalSeen', true)

        navigate('/'+currentLanguage+'/community-health/covid-19')
    }
    
    render() {
        const {
            children,
            location,
            data
        } = this.props

       // console.log(Cookies.get('foo'))

        const currentPath = location.pathname

        const currentLanguage = getLanguageFromPath(currentPath)

        let languagesArray = data.allContentfulLanguage.edges

        const languageIndex = getLanguageIndex(languagesArray, location.pathname)

        const headerData = data.allContentfulLanguage.edges[languageIndex].node.headerMenuItems

        const footerData = data.allContentfulLanguage.edges[languageIndex].node.footerMenu

        let pageClass = 'page-container ' + currentLanguage

        let searchArray = []

        languagesArray.map(edge => {

            let languageCode = edge.node.node_locale

            let navigationPagesArray = edge.node.navigationPages

            navigationPagesArray.map(navigationPage => {

                let contentPagesArray = navigationPage.contentPages

                if (contentPagesArray != null) {
                    contentPagesArray.map(contentPage => {

                        let contentPageTitle = contentPage.title

                        if (contentPage.resources != null) {
                            let contentPageContent = contentPage.resources.json

                            searchArray.push([languageCode, contentPageTitle, contentPageContent])
                        }
                    })
                }

            })

        })

        console.log(Cookies.get('modalSeen'))

        const modalClassName = `modal-container ${ this.state.modalVisible ? 'visible' : '' }`

        return (
            <div className={pageClass}>
                <header>
                    <Header
                        location={location}
                        allLanguages={data.allContentfulLanguage.edges}
                        path={currentPath}
                        currentLanguage={currentLanguage}
                        data={headerData}
                        languageIndex={languageIndex}
                    />
                </header>
                <div className={modalClassName}>
                    <div className="modal-box">
                        <a className="close" onClick={() => { this.closeModal() }}>
                            <img src={getImage('close.png')}/>
                        </a>
                        <img className="languages" src={data.allContentfulLanguage.edges[languageIndex].node.modalImage.file.url}/>
                        <h1>
                            {data.allContentfulLanguage.edges[languageIndex].node.modalMessage}
                        </h1>
                        <a className="button" onClick={() => { this.yesPlease(currentLanguage) }}>
                            {data.allContentfulLanguage.edges[languageIndex].node.modalButtonLabel}
                        </a>
                    </div>
                </div>
                <main>
                    { children }
                </main>
                <footer>
                    <Footer
                        languageIndex={currentLanguage}
                        location={location}
                        data={footerData}
                    />
                </footer>
            </div>
        )
    }
}

export default props => (
    <StaticQuery
        query={graphql`
        query {
            allContentfulLanguage {
                edges {
                    node {
                        node_locale
                        name
                        bannerMessage
                        modalImage {
                            file {
                                url
                            }
                        }
                        modalMessage
                        modalButtonLabel
                        mobileMenuMenuLabel
                        mobileMenuCloseLabel
                        mobileMenuBackLabel
                        clinicBoxCopy
                        contentPageTableTabLinksLabel
                        homeTranslationInCurrentLanguage
                        headerMenuItems {
                            __typename
                            slug
                            label
                            icon {
                                file {
                                    url
                                }
                            }
                            iconOnHover {
                                file {
                                    url
                                }
                            }
                            mobileIcon {
                                file {
                                    url
                                }
                            }
                            navigationPage {
                                title
                                englishTitle
                                id
                                contentPages {
                                    title
                                    englishTitle
                                    icon {
                                        file {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                        searchPlaceholder
                        searchButtonLabel
                        footerMenu {
                            phoneNumberALabel
                            phoneNumberA
                            phoneNumberBLabel
                            phoneNumberB
                            logo {
                                file {
                                    url
                                }
                            }
                            logos {
                                file {
                                    url
                                }
                            }
                            links {
                                label
                                url
                            }
                            disclaimerLine1
                            disclaimerLine2 {
                                disclaimerLine2
                            }
                        }
                        navigationPages {
                            title
                            id
                            englishTitle
                            contentPages {
                                englishTitle
                                title 
                                resources {
                                    json
                                }
                            }
                        }
                    }
                }
            }
        }
        `}
        render={data => (
            <Location>
                {({ location }) => (
                    <LayoutWrapper data={data} {...props} location={location} />
                )}
            </Location>
        )}
    />
)