import React, { Component } from 'react';
import Link from 'gatsby-link';
import './HeaderMenuItem.scss';
import HeaderSubMenuItem from './HeaderSubMenuItem/HeaderSubMenuItem';

import { 
    getSlug,
    getLabel,
    getLanguageFromPath,
    getColour,
    getCurrentSection
} from 'utils'

export class HeaderMenuItem extends Component {
    state = {
        isOpen: false,
        isHover: false,
    }

    toggleOpenItem = _ => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    // given a logo, load the relevant icon
    getIconForItem = isActive => {
        const { item } = this.props

        let itemIcon = '#';

        // try to get the image
        try {
            if (isActive && item.slug != 'logo' && item.slug != 'clinic-locations') {
                itemIcon = item.iconOnHover ? item.iconOnHover.file.url : null
            } else {
                itemIcon = item.icon ? item.icon.file.url : null
            }
        } catch (e) {
            itemIcon = null;
        }

        return itemIcon;
    }

    mouseEnter = _ => {
        this.setState({ isHover: true })
    }

    mouseLeave = _ => {
        this.setState({ isHover: false })
    }

    render() {
        const {
            location,
            item
        } = this.props

        const {
            isHover
        } = this.state

        const {
            navigationPage,
        } = item

        const currentPath = location.pathname
        const currentLanguage = getLanguageFromPath(currentPath)
        const currentSection = getCurrentSection(location)

        const slug = getSlug(item)
        const label = getLabel(item)
        const url = `/${currentLanguage}/${slug !== 'logo' ? slug : ''}`

        let itemClassName = 'header-menu-item ' + slug

        if (item.isLast) {
            itemClassName += ' is-last'
        }

        let labelStyle
        let isActiveOrHovered = false

        if (isHover || slug == currentSection) {
            labelStyle = { color: getColour(url) }

            isActiveOrHovered = true
        }

        return (
            <li
                key={item.path}
                className={itemClassName}
                onMouseEnter={this.mouseEnter}
                onMouseLeave={this.mouseLeave}
            >
                <Link to={url}>
                    <span>
                        <img src={this.getIconForItem(isActiveOrHovered)} alt={`${slug}`} />
                        { slug !== 'logo' &&
                            <label style={labelStyle}>
                                {label}
                            </label>
                        }
                    </span>
                </Link>
                { navigationPage && navigationPage.contentPages ?
                    <ul className="sub-menu">
                        { navigationPage.contentPages.map((page, i) =>
                            <HeaderSubMenuItem
                                location={location}
                                item={page}
                                key={i}
                                parent={url}
                            />
                        )}
                    </ul>
                    : null
                }
            </li>
        )
    }
}

export default HeaderMenuItem;
