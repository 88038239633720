import React, { Component } from 'react'
import { getLanguageFromPath } from 'utils/routing'
import { navigate } from "gatsby"
import './SearchBox.scss'
import { getImage } from 'utils/get-image'

export class SearchBox extends Component {
    state = {
        searchTerm: ''
    }

    isMobile = false

    handleChange = e => {
        const value = e.target.value

        if (this.props.handleChange) {
            this.props.handleChange(value)
        } else {
            this.setState({
                searchTerm: value
            })
        }
    }

    handleFormSubmit = e => {
        const {
            updateSearchTerm,
            isClinicLocations
        } = this.props

        e.preventDefault()

        if (!isClinicLocations) {
            const term = e.target[0].value

            if (term) {
                const currentLanguage = getLanguageFromPath({ location }.location.pathname)
                const resultsUrl = `/${currentLanguage}/search-results?s=${term.toLowerCase()}`

                navigate(resultsUrl, { state: { searchTerm: term.toLowerCase() } })
            } else {
                return
            }

        } else {
            updateSearchTerm(e.target[0].value)
        }

        document.activeElement.blur()

    }

    render() {
        if (typeof window != 'undefined') {
            this.isMobile = window.outerWidth <= 500 ? true : false
        }

        const {
            searchPlaceholder,
            searchButtonLabel,
            isTopBar,
            handleChange,
            searchTerm,
            isClinicLocations
        } = this.props

        let className = 'search-box-container'

        if (isTopBar) {
            className += ' shrunk'
        }

        // can work with external values, or internal
        const value = handleChange ? searchTerm : this.state.searchTerm

        const mobileIcon = typeof isClinicLocations == 'undefined' ? 'search-icon-mobile.png' : 'search-icon.png'
        const desktopIcon = 'search-icon.png'

        return (
            searchPlaceholder ?
                <div className={className}>
                    <form className="search" onSubmit={this.handleFormSubmit}>
                        <input
                            className="search-box"
                            type="text"
                            placeholder={searchPlaceholder}
                            value={value}
                            onChange={this.handleChange}
                        />
                        <button type="submit" className="button" >
                            {!isTopBar ?
                                <span>
                                    {searchButtonLabel}
                                </span>
                                :
                                <img src={getImage(this.isMobile ? mobileIcon : desktopIcon )} />
                            }
                        </button>
                    </form>
                </div>
                : null
        )
    }

}

export default SearchBox