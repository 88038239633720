import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import SearchResult from 'src/components/SearchResult/SearchResult'
import LayoutWrapper from 'src/layouts/LayoutWrapper'
import './searchResults.scss'
import { getLanguageFromPath } from 'utils/routing'
import Link from 'gatsby-link'
import SearchBox from 'components/SearchBox/SearchBox'
import Select from 'react-select'
import { getLanguageIndex } from 'utils/language'
import slugify from 'slugify'

import {
    parseQueryString
} from 'utils'

import { cloneDeep } from 'lodash'
import isEqual from 'react-fast-compare'

class SearchResults extends Component {

    results = []
    count = []
    isMobile = false

    state = {
        tabIndex: null,
        currentLanguageIndex: null,
        selectedOption: null,
    }

    componentWillMount = _ => {
        const thisLanguageIndex = this.props.data.allContentfulLanguage.edges.findIndex(edge => {

            const thisLanguage = getLanguageFromPath(this.props.location.pathname)

            return edge.node.node_locale === thisLanguage
        })

        this.setState({
            currentLanguageIndex: thisLanguageIndex
        })

        this.getSearchResults()
    }

    componentDidUpdate = prevProps => {
        const currentSearchTerm = this.getSearchTerm()
        const prevSearchTerm = this.getSearchTerm(prevProps)

        if (currentSearchTerm !== prevSearchTerm) {
            this.getSearchResults()
        }
    }

    componentDidMount() {
        this.setState({
            tabIndex: null
        })
    }

    getAllContentPageData = _ => {
        const {
            data
        } = this.props

        const contentPageData = []

        const allLanguages = data.allContentfulLanguage.edges

        //Looping all data into an array starting with languages

        allLanguages && allLanguages.forEach(edge => {
            const allNavigationPages = edge.node.navigationPages

            const languageCode = edge.node.node_locale

            const languageIndex = data.allContentfulLanguage.edges.findIndex(edge => {
                return edge.node.node_locale === languageCode
            })

            contentPageData.push({
                languageCode,
                navigationPages: []
            })

            allNavigationPages && allNavigationPages.forEach(navigationPage => {
                const allContentPages = navigationPage.contentPages

                const thisNavigationPageSlug = slugify(navigationPage.englishTitle)

                const navigationPageIndex = data.allContentfulLanguage.edges[languageIndex].node.navigationPages.findIndex(navPage => {
                    return slugify(navPage.englishTitle) === thisNavigationPageSlug
                })

                contentPageData[languageIndex].navigationPages.push({
                    navigationPage,
                    contentPages: []
                })

                //Pushing content pages to array

                allContentPages && allContentPages.forEach(contentPage => {
                    if (contentPage) {
                        contentPageData[languageIndex].navigationPages[navigationPageIndex].contentPages.push(contentPage)
                    }
                })
            })
        })

        return contentPageData
    }

    getSearchTerm = (props = this.props) => {
        const {
            location
        } = props

        let searchTerm = ''

        if (location.search) {
            const searchObject = parseQueryString(location.search.replace('?', ''))

            if (searchObject.s) {
                searchTerm = searchObject.s
            }
        }

        if (!searchTerm) {
            searchTerm = location.state && location.state.searchTerm ? location.state.searchTerm.toLowerCase() : ''
        }

        return searchTerm
    }

    getSearchResults = _ => {
        const oldResults = cloneDeep(this.results)

        this.results = []

        const normalisedSearchTerm = this.getSearchTerm()

        const contentPageData = this.getAllContentPageData()

        //Looping through data for each language

        contentPageData.forEach((language, languageIndex) => {
            const languageCode = language.languageCode

            //Pushing language 
            this.results.push({
                language: languageCode,
                categories: [],
                allResults: []
            })

            //Looping through each navigation page 
            language.navigationPages.forEach(navigationPage => {

                //Looping through each content page 
                navigationPage.contentPages.forEach(pageData => {
                    const pageTitle = pageData.title
                    const isSearchTermInTitle = pageTitle && pageTitle.includes(normalisedSearchTerm)
                    const allContentBlocks = pageData.introduction ? pageData.introduction.contentBlocks : []

                    let isSearchTermInBlock = false

                    //Looping through each content block

                    allContentBlocks.some(contentBlock => {
                        const contentBlockContent = contentBlock.content ? documentToPlainTextString(contentBlock.content.json).toLowerCase() : null

                        const tableRows = contentBlock.table ? contentBlock.table.tabs : []

                        //Looping through each table row

                        tableRows && tableRows.some(tableRow => {
                            const tableRowContent = tableRow.content ? documentToPlainTextString(tableRow.content.json).toLowerCase() : null

                            if (contentBlockContent) {
                                if (contentBlockContent.includes(normalisedSearchTerm) || (tableRowContent && tableRowContent.includes(normalisedSearchTerm))) {
                                    isSearchTermInBlock = true
                                    return true
                                }
                            }

                        })

                    })

                    if (isSearchTermInTitle || isSearchTermInBlock) {
                        const parentTitle = navigationPage.navigationPage.englishTitle ? navigationPage.navigationPage.englishTitle : navigationPage.navigationPage.title
                        const navigationPageSlug = slugify(navigationPage.navigationPage.englishTitle)

                        const pageDataWithParent = {
                            ...pageData,
                            parentPage: parentTitle
                        }

                        this.results[languageIndex].allResults.push(pageDataWithParent)

                        const categoryIndexIfExisting = this.results[languageIndex].categories.findIndex(category => {
                            return slugify(category.title) === navigationPageSlug
                        })

                        if (categoryIndexIfExisting !== -1) {
                            this.results[languageIndex].categories[categoryIndexIfExisting].pages.push(pageDataWithParent)
                        } else {
                            this.results[languageIndex].categories.push({
                                title: navigationPage.navigationPage.title,
                                slug: navigationPageSlug,
                                pages: [pageDataWithParent]
                            })
                        }
                    }

                })
            })
        })

        if (!isEqual(this.results, oldResults)) {
            this.forceUpdate()
        }
    }

    setTab = tabIndex => {
        this.setState({
            tabIndex
        })
    }

    handleChange = selectedOption => {
        const index = { selectedOption }.selectedOption.index

        this.setState({
            selectedOption: selectedOption,
            tabIndex: index
        })

    }

    render() {
        if (typeof window != 'undefined') {
            this.isMobile = window.outerWidth <= 500 ? true : false
        }

        const {
            location,
            data
        } = this.props

        const {
            currentLanguageIndex,
            tabIndex,
            selectedOption
        } = this.state

        let thisLanguage = getLanguageFromPath(location.pathname)

        const resultsForThisLanguage = this.results[currentLanguageIndex]

        const dropdownOptions = []

        dropdownOptions.push({
            value: null,
            label: 'All (' + resultsForThisLanguage.allResults.length + ')',
            index: null,
        })

        resultsForThisLanguage.categories.forEach((category, index) => {
            dropdownOptions.push({
                value: category.title.toLowerCase(),
                label: category.title + ' (' + category.pages.length + ')',
                index: index,
            })
        })

        let searchTerm = location.state && location.state.searchTerm ? location.state.searchTerm : ''

        const path = location.pathname

        const allLanguages = data.allContentfulLanguage.edges

        const languageIndex = getLanguageIndex(allLanguages, path)

        const searchButtonLabel = allLanguages[languageIndex].node.searchButtonLabel

        return (
            <LayoutWrapper>
                <div className={'search-results-container'}>
                    <h1>
                        {allLanguages[languageIndex].node.searchResultsPageTitle}
                    </h1>
                    {!this.isMobile ?
                        <SearchBox
                            getSearchResults={this.getSearchResults}
                            searchPlaceholder={searchTerm}
                            searchButtonLabel={searchButtonLabel}
                        />
                        : null
                    }
                    <div className="sidebar">
                        <div className="filter-box">
                            <h2>
                                {allLanguages[languageIndex].node.searchResultsFilterLabel}
                            </h2>
                            {!this.isMobile ?
                                <ul className="menu">
                                    <li className={tabIndex == (null) ? 'active' : null}>
                                        <button onClick={_ => this.setTab(null)}>
                                            {allLanguages[languageIndex].node.allTranslationInCurrentLanguage + ' '}
                                            ({resultsForThisLanguage.allResults.length})
                                        </button>
                                    </li>
                                    {resultsForThisLanguage.categories.map((category, index) => (
                                        <li
                                            className={tabIndex == (index) ? 'active' : null}
                                            key={index}
                                        >
                                            <button onClick={_ => { this.setTab(index) }}>
                                                {category.title} ({category.pages.length})
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                                :
                                <Select
                                    value={selectedOption}
                                    onChange={this.handleChange}
                                    options={dropdownOptions}
                                    placeholder={dropdownOptions[0].label}
                                />
                            }
                        </div>
                        <div className="clinic-box">
                            <h2>
                                {allLanguages[languageIndex].node.clinicBoxCopy}
                            </h2>
                            <Link to={`/${thisLanguage}/clinic-locations`}>
                                {allLanguages[languageIndex].node.clinicBoxCta}
                            </Link>
                        </div>
                    </div>
                    <div className="main">
                        <p className="count">
                            {resultsForThisLanguage.allResults.length == 0 ?
                                allLanguages[languageIndex].node.searchResultsErrorMessage
                                :
                                allLanguages[languageIndex].node.searchResultsLabel + ' "' + (location.state && location.state.searchTerm ? location.state.searchTerm + '"' : '')
                            }
                        </p>
                        <ul className="tabs-container">
                            <li className={'visible'}>
                                <ul className="results">
                                    {tabIndex != null ?
                                        resultsForThisLanguage.categories[tabIndex].pages.map((page, index) => (
                                            <SearchResult
                                                key={index}
                                                result={page}
                                                searchTerm={location.state && location.state.searchTerm ? location.state.searchTerm : ''}
                                                navigationPage={resultsForThisLanguage.categories[tabIndex]}
                                                location={location}
                                            />
                                        ))
                                        :
                                        resultsForThisLanguage.categories.map(category => (
                                            category.pages.map((page, index) => (
                                                <SearchResult
                                                    key={index}
                                                    result={page}
                                                    searchTerm={location.state && location.state.searchTerm ? location.state.searchTerm : ''}
                                                    navigationPage={resultsForThisLanguage.categories[tabIndex]}
                                                    location={location}
                                                />
                                            ))
                                        ))}
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </LayoutWrapper>
        )
    }
}

export default props => (
    <StaticQuery
        query={graphql`
        query {
            allContentfulLanguage {
                edges {
                    node {
                        node_locale
                        searchButtonLabel
                        searchResultsPageTitle
                        searchResultsFilterLabel
                        allTranslationInCurrentLanguage
                        clinicBoxCopy
                        clinicBoxCta
                        searchResultsLabel
                        searchResultsErrorMessage
                        navigationPages {
                            englishTitle
                            title
                            contentPages {
                                englishTitle
                                title 
                                resources {
                                    json
                                }
                                introduction {
                                    contentBlocks {
                                        content {
                                            json
                                        }
                                        table {
                                            tabs {
                                                title
                                                content {
                                                    json
                                                } 
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        `}
        render={data => (
            <SearchResults data={data} {...props} />
        )}
    />
)