export const getColour = (input, alpha = 1) => {

    let slug

    let rgbArray = []

    if (typeof input === 'object') {
        slug = input.pathname.split('/')[2]
    } else {
        slug = input
    }

    switch (slug) {
        case 'healthcare-in-australia':
            rgbArray = [176, 208, 152]
            break
        case 'family-planning':
            rgbArray = [150, 210, 233]
            break
        case 'pregnancy-and-postnatal-care':
            rgbArray = [224, 129, 133]
            break
        case 'sexual-health':
            rgbArray = [243, 186, 86]
            break
        case 'community-health':
            rgbArray = [173, 171, 204]
            break
        default:
            return null
    }

    rgbArray.push(alpha)

    return `rgba(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]}, ${rgbArray[3]})`
}

