import React, { Component } from 'react'
import Link from 'gatsby-link'
import './MobileMenu.scss'
import {navigate} from 'gatsby'

import { 
    getImage,
    getSlug,
    getLabel,
    getLanguageFromPath,
    getViewportHeight
} from 'utils'

import { globalHistory } from "@reach/router"

export class MobileMenu extends Component {
    state = {
        mainMenuVisible: false,
        subMenuVisible: null,
        subMenuIndex: 1
    }

    openMenu() {
        this.setState({
            mainMenuVisible: !this.state.mainMenuVisible,
            subMenuVisible: false
        })
    }

    openSubMenu(index) {
        if (index === null) {
            this.setState({
                subMenuVisible: false
            })
        } else {
            this.setState({
                subMenuVisible: true,
                subMenuIndex: index
            })
        }
    }

    goToClinic = () => {

        navigate('/en/clinic-locations')
    }

    render() {
        const {
            menuItems,
            menuLabel,
            closeLabel,
            backLabel,
            clinicBoxCopy,
        } = this.props

        const {
            subMenuIndex,
            mainMenuVisible,
            subMenuVisible
        } = this.state

        const currentMenuItem = menuItems[subMenuIndex]

        const {
            navigationPage
        } = currentMenuItem

        const slug = getSlug(currentMenuItem)

        const menuHeightStyle = {
            height: getViewportHeight() + 'px'
        }

        let mainMenuClassName = 'main-menu'

        if (mainMenuVisible) {
            mainMenuClassName += ' visible'
        }

        let subMenuClassName = 'sub-menu ' + slug

        if (subMenuVisible) {
            subMenuClassName += ' visible'
        }

        const currentLanguage = getLanguageFromPath(globalHistory.location.pathname)

        return (
            <div className={'mobile-menu'}>
                <div className={'top-bar'}>
                    <Link to={`/`}>
                        <img src={getImage('shifra-logo.png')} alt={`shifra-logo`} />
                    </Link>
                    <a className="button" onClick={() => {this.openMenu()}}>
                        { mainMenuVisible ? closeLabel : menuLabel}
                    </a>
                </div>
                <div className={'bottom-bar'}>
                    <span>
                        <Link to={`/en/clinic-locations`}>
                            <img src={getImage('clinic-white.png')} />
                            <p>
                                {clinicBoxCopy}
                            </p>
                        </Link>
                    </span>
                </div>
                <div className="menu-container">
                    <ul className={mainMenuClassName} style={menuHeightStyle}>
                        {menuItems.map((item, index) => {
                            const slug = item.slug

                            if (slug != 'logo') {
                                return (
                                    <li key={index} className={slug}>
                                        { 
                                            slug == 'clinic-locations' ? 
                                            
                                            <a onClick={_ => { this.goToClinic() }}>
                                                <img 
                                                    src={getImage('clinic-white.png')}
                                                />
                                                <span>
                                                    { getLabel(item) }
                                                </span>
                                                <img 
                                                    className="arrow" 
                                                    src={getImage('white-right-arrow.png')} 
                                                />
                                            </a>
                                        : 

                                            <a onClick={_ => { this.openSubMenu(index) }}>
                                                <img 
                                                    src={item.mobileIcon ? item.mobileIcon.file.url : null} 
                                                />
                                                <span>
                                                    { getLabel(item) }
                                                </span>
                                                <img 
                                                    className="arrow" 
                                                    src={getImage('white-right-arrow.png')} 
                                                />
                                            </a>

                                        }
                                    </li>
                                )
                            }
                        })}
                    </ul>
                    <ul className={subMenuClassName} style={menuHeightStyle}>
                        <li>
                            <a onClick={_ => { this.openSubMenu(null) }}>
                                &lsaquo; {backLabel}
                            </a>
                            <span>
                                { currentMenuItem.label}
                            </span>
                        </li>
                        {navigationPage && navigationPage.contentPages.map((item, index) => {
                            return (
                                <li key={index}>
                                    <a href={`${currentLanguage}/${slug}/${getSlug(item)}`}>
                                        { item.icon ?
                                            <img src={item.icon ? item.icon.file.url : null} />
                                        : null }
                                        <span>
                                            { getLabel(item) }
                                        </span>
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }
}


export default MobileMenu