export const getImage = path => {
    let image = '#';

    // try to get the image
    try {
        image = require(`content/${path}`);
    } catch (e) {
        image = null;
    }

    return image;
}