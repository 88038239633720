import React, { Component } from 'react'
import HeaderMenuItem from './HeaderMenuItem/HeaderMenuItem';
import './HeaderMenu.scss';

export class HeaderMenu extends Component {
    state = {
        hasScrolled: false
    }

    componentWillMount() {
        typeof window !== 'undefined' && window.addEventListener('resize', this.calculateNavHeight)
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = e => {
        const scrollTop = window.pageYOffset

        if (scrollTop > 100) {

            if (!this.state.hasScrolled) {
                this.setState({
                    hasScrolled: true
                })
            }
        } else if (this.state.hasScrolled) {
            this.setState({
                hasScrolled: false
            })
        }
    }

    render() {

        const {
            hasScrolled
        } = this.state

        const {
            menuItems,
            location
        } = this.props

        let menuClassName = 'header-menu'

        if (hasScrolled) {
            menuClassName += ' scrolled'
        }

        return (
            <nav
                className={menuClassName}
            >
                <ul>
                    {menuItems.map((item, i) =>
                        <HeaderMenuItem
                            location={location}
                            item={item}
                            key={i}
                        />
                    )}
                </ul>
                <div className="clearfix" />
            </nav>
        )
    }
}

export default HeaderMenu;
