export const getLanguageFromPath = path => {
    const segmentsArray = path.split('/')
    const language = segmentsArray[1]

    return language
}

export const getNewUrl = (path, languageSlug) => {
    const segmentsArray = path.split('/')

    segmentsArray[1] = languageSlug

    return segmentsArray.join('/')
}

export const parseQueryString = query => {
    let vars = query.split("&");
    let query_string = {};
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        let key = decodeURIComponent(pair[0]);
        let value = decodeURIComponent(pair[1]);
        // If first entry with this name
        if (typeof query_string[key] === "undefined") {
            query_string[key] = decodeURIComponent(value);
            // If second entry with this name
        } else if (typeof query_string[key] === "string") {
            let arr = [query_string[key], decodeURIComponent(value)];
            query_string[key] = arr;
            // If third or later entry with this name
        } else {
            query_string[key].push(decodeURIComponent(value));
        }
    }
    return query_string;
}