import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import React from 'react'

import slugify from 'slugify'

export const processJson = object => {
    const options = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: node => {
                if(!node.data.target.fields){
                    return null
                }

                const { title, description, file } = node.data.target.fields
                const mimeType = file['en'].contentType
                const mimeGroup = mimeType.split('/')[0]

                switch (mimeGroup) {
                    case 'image':
                    return <img src={file["en"].url} />
                    case 'application':
                    return <a href={file['en-US'].url}>
                                { title ? title['en-US'] : file['en-US'].details.fileName }
                            </a>
                    default:
                    return null
                }
            },
            [INLINES.HYPERLINK]: node => <a target="_blank" href={node.data.uri}>{node.content[0].value}</a>,
        },
    }

    if(object) {
        if(object.json){
            return documentToReactComponents(object.json, options)
        } else{
            return ''
        }
    }
}

export const getSlug = item => {
    let slug = ''
    let valueToSlug = null
    
    if(item.navigationPage){
        valueToSlug = item.navigationPage.englishTitle
    }else if(item.englishTitle){
        valueToSlug = item.englishTitle
    }else if(item.title){
        valueToSlug = item.title
    }else if(item.label){
        valueToSlug = item.label
    }

    if(!valueToSlug){
        console.log(item)
    }

    slug = slugify(valueToSlug).toLowerCase()

    return slug
}

export const getLabel = item => {
    if(item.title){
        // sub-menu item
        return item.title
    }else if(item.navigationPage){
        // top nav item
        return item.navigationPage.title
    }else{
        // other/generic
        return item.label
    }
}